<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="query.status" placeholder="" clearable>
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="通过" :value="1"></el-option>
          <el-option label="拒绝" :value="2"></el-option>
        </el-select>
      </el-form-item>
       <el-form-item label="系统匹配">
        <el-select v-model="query.is_find" placeholder="系统匹配" clearable>
          <el-option label="匹配成功" :value="true"></el-option>
          <el-option label="匹配失败" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="员工">
        <el-input
          placeholder="要搜索的教练名称"
          v-model="query.admin_name"
        ></el-input>
      </el-form-item>
      <el-form-item label="提交时间">
        <el-date-picker
          v-model="query.createTime"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #name="{ row }">
        <div>
          <link-to :to="{ name: 'coachDetails', query: { id: row.coach_id } }">
            {{ row.coach_name }}</link-to
          >
        </div>
      </template>
      <template #is_find="{ row }">
        <el-tag size="small" type="info" v-if="row.is_find">匹配</el-tag>
        <el-tag size="small" type="danger" v-else>未匹配</el-tag>
      </template>
      <template #status="{ row }">
        <el-tag size="small" type="info" v-if="row.status === 0">待审核</el-tag>
        <el-tag size="small" type="success" v-if="row.status === 1"
          >通过</el-tag
        >
        <el-tag size="small" type="danger" v-if="row.status === 2">拒绝</el-tag>
      </template>
      <template #handle="{ row }">
        <el-button size="small" type="primary" @click="openDetail(row)"
          >详情</el-button
        >
        <el-button
          size="small"
          v-if="row.status === 0"
          type="success"
          @click="updateStatus(row, 1)"
          >通过</el-button
        >
        <el-button
          size="small"
          v-if="row.status === 0"
          type="danger"
          @click="openInfo(row)"
          >拒绝</el-button
        >
      </template>
    </auto-table>

    <!-- 审核意见 -->
    <el-dialog title="拒绝说明" :visible.sync="show" width="30%">
      <div>
        <el-form label-width="80px">
          <el-form-item label="员工">
            {{ curAuth.admin_name }}
          </el-form-item>
          <el-form-item label="说明">
            <el-input
              type="textarea"
              placeholder="拒绝理由"
              :autosize="{ minRows: 3, maxRows: 6 }"
              v-model="remark"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">关闭</el-button>
        <el-button type="danger" @click="updateStatus(curAuth, 2)"
          >拒绝</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="详情" :visible.sync="detailShow" width="30%">
      <div>
        <el-form label-width="80px">
          <el-form-item label="提交人">
            {{ detail.admin_name }}
          </el-form-item>
          <el-form-item label="住户电话">
            {{ detail.phone }}
          </el-form-item>
          <el-form-item label="地址">
            {{ detail.floor_no }}-{{ detail.unit_no }}-{{ detail.room_no }}
          </el-form-item>
          <el-form-item label="系统匹配">
            <el-tag size="small" type="info" v-if="detail.is_find">匹配</el-tag>
            <el-tag size="small" type="danger" v-else>未匹配</el-tag>
          </el-form-item>
          <el-form-item label="图片" v-if="detail.pic">
            <el-image
              v-for="(url, i) in detail.pic.split(',')"
              :key="i"
              style="width: 100px; height: 100px"
              :src="url"
              :preview-src-list="detail.pic.split(',')"
            >
            </el-image>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailShow = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "用户激活审核", secondNav: "用户激活审核列表" },
      DataList: [],
      query: {
        status: 0,
        admin_name: "",
        createTime: [],
        is_find:null
      },
      Option: [
        { name: "提交人", value: "admin_name" },
        { name: "区域", value: "area" },
        { name: "楼栋", value: "floor_no" },
        { name: "单元", value: "unit_no" },
        { name: "门牌", value: "room_no" },
        { name: "系统查询", value: "is_find", type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "提交时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom", width: "200px" },
      ],
      Total: 0,
      curAuth: {},
      remark: "",
      show: false,
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
      detail: {},
      detailShow: false,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    openDetail(row) {
      this.detail = row;
      this.detailShow = true;
    },
    openInfo(row) {
      this.curAuth = row;
      this.show = true;
    },
    updateStatus(row, status) {
      let data = {
        id: row.id,
        status,
        remark: this.remark || null,
        handle_id: this.adminInfo.id,
        handle_name: this.adminInfo.name,
       
      };
      this.$post("/user/activeRecord/update", data).then((res) => {
        this.$message("成功");
        this.getList(1);
        this.show = false;
        this.remark = "";
      });
    },
    //列表
    getList(v) {
      let page = v || 1;
      let params = {
        currentPage: page,
        pageSize: 10,
        status: this.query.status,
        admin_name: this.query.admin_name || null,
         is_find:this.query.is_find
      };
      if (this.query.createTime !== null && this.query.createTime.length > 0) {
        params.start_time = this.query.createTime[0] + " 00:00:00";
        params.end_time = this.query.createTime[1] + " 23:59:59";
      }

      this.$axios({
        url: "/user/activeRecord/queryManagerListPage",
        params,
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>